<!--仓库库存-->
<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button class="itemBtn btnColor" @click="locationMove(1)"
        >包裹移位</el-button
      >
<!--      <el-button class="itemBtn btnColor" @click="locationMove(2)"-->
<!--        >货架移位</el-button-->
<!--      >-->
      <!-- <el-button class="itemBtn btnColor" @click="abnormalDeal"
        >异常处理</el-button > -->
      <el-button class="itemBtn btnColor" @click="myModel">表格设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn"
          >导出<i class="el-icon-arrow-down el-icon--right"></i
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.courierNumber"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">仓库:</div>
            <div class="singleinpright">
              <el-select
                v-model="queryData.storageId"
                placeholder="请选择"
                @change="changeCn"
                clearable
              >
                <el-option
                  v-for="item in storageIdArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">货区:</div>
            <div class="singleinpright">
              <el-select
                v-model="queryData.cargoareaNumber"
                placeholder="请选择仓库"
                @change="changeFn"
                clearable
                filterable
              >
                <el-option
                  v-for="item in cargoareaNumArr"
                  :key="item.cargoareaNumber"
                  :label="item.cargoareaName"
                  :value="item.cargoareaNumber"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">货架:</div>
            <div class="singleinpright">
              <el-select
                v-model="queryData.frameNumber"
                placeholder="请选择货区"
                clearable
                filterable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in frameNumArr"
                  :key="item.frameNumber"
                  :label="item.frameName"
                  :value="item.frameNumber"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.memberId"
                placeholder="请输入内容"
                @keyup.enter.native="queryBtn_ok"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">团号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.teamCode"
                placeholder="请输入内容"
                @keyup.enter.native="queryBtn_ok"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">异常状态:</div>
            <div class="singleinpright">
              <el-select
                v-model="queryData.exceptionStatus"
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in exceptionStatusArr"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinp_time">
            <div class="timeBox queryItemTime">
              <div class="right_inpu">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="time_first"
                  @change="paymentTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="入库开始日期"
                  end-placeholder="入库结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="item_left" v-if="hasPermiss('bgrk_cangkukucun:query')">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="resetFeild">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table
      :height="tableHeigth"
      ref="mytable"
      :data="tableData"
      stripe
      :row-class-name="tableRowClassName"
      highlight-current-row
      border
      :header-cell-style="{ background: '#f0f0f0' }"
      show-summary
      :summary-method="getSummaries"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed="left"
        align="center"
        label="序号"
        type="index"
        width="55"
      >
      </el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <template v-for="(item, index) in myTableHeard">
        <template v-if="item.field === 'length'">
          <el-table-column
            :key="index"
            :label="item.name"
            :min-width="item.width"
            align="center"
            :show-overflow-tooltip="true"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
            <template slot-scope="scope">
              <span
                >{{ scope.row.length || 0 }} * {{ scope.row.width || 0 }} *
                {{ scope.row.height || 0 }}</span
              >
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :min-width="item.width"
            align="center"
            :show-overflow-tooltip="true"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </template>
    </el-table>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageStart"
      :total="pageCount"
      :sizeList="sizeList"
      :size="pageTotal"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>
    <!-- //排序 -->
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>
    <el-dialog
      title="上传编辑好的导出模板"
      :visible.sync="show_upload"
      append-to-body
      width="40%"
      center
    >
      <el-upload
        :action="uploadUrl"
        :show-file-list="true"
        :file-list="fileList"
        :limit="1"
        :http-request="uploadSectionFile"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">上传模板</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_upload = false">关 闭</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="包裹移位"
      :visible.sync="isDT"
      :before-close="closebut"
      center
      width="60%"
      @close="resetForm('confirmForm')"
    >
      <div class="contentDT">
        <div class="firstDT">
          <div>快递单号：</div>
          <div>
            <el-select
              v-model="expressNum"
              filterable
              remote
              reserve-keyword
              value-key="courierNumber"
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              @change="SE"
              :loading="loading"
            >
              <el-option
                v-for="item in expressNumArr"
                :key="item.courierNumber"
                :label="item.courierNumber"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            type="warning"
            size="mini"
            @click="QE"
            style="margin-left: 10px"
            >查 询</el-button
          >
        </div>
        <div class="newForm">
          <el-form ref="confirmForm" :model="confirmForm" label-width="100px">
            <div class="inlineDiv">
              <div class="queryItem1">
                <el-form-item label="新仓库:" prop="storageId">
                  <el-select
                    class="queryInput"
                    v-model="confirmForm.storageId"
                    filterable
                    placeholder="请选择"
                    @change="changeCn"
                    clearable
                  >
                    <el-option
                      v-for="item in storageIdArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="新库区:" prop="cargoareaId">
                  <el-select
                    class="queryInput"
                    v-model="confirmForm.cargoareaId"
                    filterable
                    @change="changeFnEdit"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in cargoareaNumArr"
                      :key="item.id"
                      :label="item.cargoareaName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="queryItem1">
                <el-form-item label="新货架:" prop="frameId">
                  <el-select
                    class="queryInput"
                    v-model="confirmForm.frameId"
                    filterable
                    placeholder="请选择"
                    clearable
                  >
                    <el-option
                      v-for="item in frameNumArr"
                      :key="item.id"
                      :label="item.frameName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <el-table
          :data="courieNumTb"
          :header-cell-style="{ background: '#f0f0f0' }"
          stripe
          border
          max-height="400px"
          size="mini"
          style="width: 80%; margin: 0 auto"
        >
          <el-table-column
            type="index"
            align="center"
            fixed
            label="序号"
            width="55"
          ></el-table-column>
          <el-table-column
            prop="frameName"
            align="center"
            label="货架号"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="cargoareaName"
            align="center"
            label="货区"
            min-width="100"
          ></el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="deleteNum(scope.$index)"
                type="text"
                size="small"
                >移 除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="warning" size="mini" @click="locationMoveConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";

export default {
  inject: ["reload"],
  components: { draggable, paging, sorttable },
  data() {
    return {
      pageGroupName: "STORAGEBOX", //  页面标识--仓库库存
      isActive: true, //  输入框绑定动态class
      judge: false, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      queryData: {
        //  搜索数据
        courierNumber: "",
        storageId: "",
        cargoareaNumber: "",
        frameNumber: "",
        memberId: "",
        teamCode: "",
        exceptionStatus: "",
        intoStockEndTime: "",
        intoStockStartTime: "",
        pageStart: 1,
        pageTotal: 0,
      },
      time_first: [],
      storageIdArr: [], //  仓库下拉
      cargoareaNumArr: [], //  货区下拉
      frameNumArr: [], //  货架下拉
      originalTabelHeadeTitle: [],
      exceptionStatusArr: [], //  异常状态下拉
      tableData: [], //  表格数据及配置
      singleData: [],
      tabelHeadeTitle: [
        {
          name: "仓库",
          field: "storageName",
          width: "80",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "memberName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "团号",
          field: "teamCode",
          width: "80",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "快递公司",
          field: "expressName",
          width: "140",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "快递单号",
          field: "courierNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "货区",
          field: "cargoareaName",
          width: "100",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: " * 原货架号",
          field: "packageStatusShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "货架号",
          field: "frameName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "入库天数",
          field: "intoStockDay",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "货物名称",
          field: "itemName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "件数",
          field: "boxCount",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "实际重量",
          field: "packageWeight",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "实际体积",
          field: "volume",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "长宽高（cm）",
          field: "length",
          width: "120",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹备注",
          field: "comment",
          width: "140",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "入库时间",
          field: "intoStockTime",
          width: "140",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      myTableHeard: [],
      className: "",
      tableHeigth: 0,
      pageStart: 1,
      pageTotal: 50,
      pageCount: 0,
      sizeList: [10, 20, 50, 100, 200],

      isDT: false,
      confirmForm: {
        cargoareaId: "",
        frameId: "",
        packageIds: [],
        storageId: "",
      },
      expressNum: "",
      loading: false,
      expressNumArr: [],
      selectData: {}, //  远程搜索选择框选中的数据
      courieNumTb: [], //  快递单号表格
      show_sortTableHeard: false, //  表格排序配置
      allCheck: false,
      sortName: "模版名",
      selectLength: 0,
      totalArr: [],
      show_upload: false, //  下载导出配置
      fieldName: [],
      head: [],
      fileList: [],
      idArr: [],
      uploadUrl: "",
    };
  },
  updated() {},
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //  获取用户自定义表头
    this.myGetStorageIdArr(); //  获取仓库下拉数据
    this.myGetExceptionStatus("package_v1.exception_status"); //  获取异常状态下拉数据
    let endtime = tools.getCurrentDate(); //今天的时间;
    let sarttime = tools.getFlexDate(-10); //30天前
    this.time_first = [sarttime, endtime];
    this.queryData.intoStockStartTime = sarttime + " 00:00:00";
    this.queryData.intoStockEndTime = endtime + " 23:59:59";
  },
  mounted() {
    this.getData();
    // this.fetTableHeight();
  },
  watch: {},
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //  表格样式i
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },

    //时间
    paymentTime(e) {
      if (e && e.length > 0) {
        this.queryData.intoStockStartTime = e[0] + " " + "00:00:00";
        this.queryData.intoStockEndTime = e[1] + " " + "23:59:59";
      } else {
        this.queryData.intoStockStartTime = "";
        this.queryData.intoStockEndTime = "";
      }
      this.queryBtn_ok();
    },

    getData() {
      //  获取数据
      let param = {};
      this.queryData.pageStart = this.pageStart;
      this.queryData.pageTotal = this.pageTotal;
      // if (this.time_first.length > 0) {
      //   this.queryData.intoStockStartTime = `${this.time_first[0]}`;
      //   this.queryData.intoStockEndTime = `${this.time_first[1]}`;
      // }
      param = this.queryData;
      Api.getPackageStorageList(param).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.pageCount = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    more() {
      //  输入框绑定动态class
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },

    changeCn(val) {
      //  仓库级联货区
      this.myGetCargoareaNumArr(val);
      this.queryData.cargoareaNumber = "";
      this.queryData.frameNumber = "";
      this.confirmForm.cargoareaId = "";
      this.confirmForm.frameId = "";
    },
    changeFn(val) {
      //  货区级联货架
      this.myGetFrameNum(val);
      this.queryData.frameNumber = "";

      this.queryBtn_ok();
    },
    changeFnEdit(val) {
      this.myGetFrameNum(val, 1);
      this.confirmForm.frameId = "";
    },
    queryBtn_ok() {
      this.pageStart = 1;
      this.getData();
    },
    remoteMethod(query) {
      //  select框远程搜索
      if (query !== "") {
        this.loading = true;
        Api.getExpressNumData({ courierNumber: query }).then((res) => {
          if (res.data.status === "success") {
            this.loading = false;
            this.expressNumArr = res.data.result;
          }
        });
      } else {
        this.expressNumArr = [];
      }
    },
    QE() {
      //  查询按钮
      if (this.expressNum !== "") {
        let i = this.courieNumTb.findIndex(
          (v) => v.packageId === this.singleData.packageId
        );
        if (i === -1) {
          this.courieNumTb.push(this.selectData);
        }
        this.selectData = {};
        this.expressNum = "";
      }
    },
    SE(val) {
      //  快递单号下拉框选中函数
      this.selectData = val;
    },
    deleteNum(index) {
      this.courieNumTb.splice(index, 1);
    },
    resetFeild() {
      this.queryData = {
        courierNumber: "",
        storageId: "",
        cargoareaNumber: "",
        frameNumber: "",
        memberId: "",
        teamCode: "",
        exceptionStatus: "",
        intoStockEndTime: "",
        intoStockStartTime: "",
        pageStart: 1,
        pageTotal: 0,
      };
      this.time_first = [];
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    locationMove(num) {
      if (num === 1) {
        this.courieNumTb = this.singleData;
        this.isDT = true;
      } else if (num === 2) {
        this.$message.warning("暂无接口");
      }
      // if (this.singleData.length === 1) {
      //
      // } else {
      //     this.$message.warning('请选择一条数据')
      // }
    },
    //关闭弹窗
    closebut() {
      this.isDT = false;
      this.confirmForm = {
        cargoareaId: "",
        frameId: "",
        packageIds: [],
        storageId: "",
      };
    },
    locationMoveConfirm() {
      this.courieNumTb.forEach((item) => {
        this.confirmForm.packageIds.push(item.packageId);
      });
      let param = {
        cargoareaId: this.confirmForm.cargoareaId,
        frameId: this.confirmForm.frameId,
        packageIds: this.confirmForm.packageIds,
        storageId: this.confirmForm.storageId,
      };
      let sign = tools.getSign(param);
      param.sign = sign;

      Api.updatePackageFrame(param).then((res) => {
        if (res.data.status === "success") {
          this.isDT = false;
          this.$message.success(res.data.message);
          this.getData();
          this.closebut();
        }
      });
    },
    abnormalDeal() {
      //  异常处理
      if (this.singleData.length === 1) {
        this.$router.push({
          path: "/exceptionhandling/Newexception",
          query: {
            editId: this.singleData[0].exceptionId,
          },
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },

    //    -------------  表格事件  --------------

    handleCurrentChange(val) {
      this.pageStart = val;
      this.getData();
    },

    handleSizeChange(val) {
      this.pageTotal = val;
      this.getData();
    },

    getSummaries(param) {
      //  合计
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property === that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    handleSelectionChange(e) {
      //  表格选择事件
      this.singleData = e;
    },
    //    -------------  表格排序事件  --------------

    getGroupFindFieldSort() {
      //  获取自定义的排序,没有就用默认的
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },

    myModel() {
      //  设置模板按钮
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength === that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },

    cancelSortHeard() {
      //  排序--取消按钮
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },

    //    -------------  条件搜索的下拉数据  --------------

    myGetStorageIdArr() {
      //  仓库
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageIdArr = res.data.result;
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表API`);
        }
      });
    },
    myGetCargoareaNumArr(storageIds) {
      //  货区
      Api.getStorageCargoareaChooseList({
        storageId: storageIds,
      }).then((res) => {
        if (res.data.status === "success") {
          this.cargoareaNumArr = res.data.result || [];
        }
      });
    },
    myGetFrameNum(val, type) {
      //  货架
      let id = undefined;
      if (type === 1) {
        id = val;
      } else {
        let index = this.cargoareaNumArr.findIndex(
          (v) => v.cargoareaNumber === val
        );
        id = this.cargoareaNumArr[index].id;
      }
      Api.getStorageCargoareaFrameChooseList({
        storageCargoareaId: id,
      }).then((res) => {
        if (res.data.status === "success") {
          this.frameNumArr = res.data.result;
        } else {
          console.log(`${res.data.message}--获取货架`);
        }
      });
    },
    myGetExceptionStatus(s) {
      //  异常状态
      Api.getStatusValList({
        tableAndFieldName: s,
      }).then((res) => {
        if (res.data.status === "success") {
          this.exceptionStatusArr = res.data.result;
        } else {
          console.log(`${res.data.message}--公共状态下拉`);
        }
      });
    },

    //     -------------  表格高度  --------------

    resetHeight() {
      //  重置table高度
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },

    fetTableHeight() {
      //  设置table高度
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },

    getHeight() {
      //  获取表格高度
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 60; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 30;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },

    //     -------------  杂项  ---------------

    handleCommand_dc(command) {
      //  导出操作
      if (command === "dc") {
        // 导出
        this.exportBtn();
      } else if (command === "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command === "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    //     -------------  下载导出  ---------------

    uploadSectionFile(param) {
      //  上传导出模板
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    exportTemplateDownload() {
      //  下载用户导出模板
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },

    templateDataExport(customizedExportId) {
      // 真正导出
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },

    upLoadBtn() {
      //  上传按钮
      this.show_upload = true;
    },

    exportBtn() {
      // 点击导出按钮
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "仓库库存列表"
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.firstDT {
  width: 100%;
  display: flex;
  line-height: 30px;
  /deep/ .el-input {
    height: 30px;
  }
  /deep/ .el-input__inner {
    height: 30px;
  }
}
</style>
